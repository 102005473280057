import { Platform } from '@ionic/angular';
import { DatiService } from './dati.service';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { map, last } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class DatiResolverService implements Resolve <Observable<any>>{

  constructor(private ds: DatiService, private platform: Platform, private domSanitizer: DomSanitizer) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot){
    console.log('resolve', localStorage.getItem('azienda'));

    if( localStorage.getItem('networkok') === '1' || this.platform.is('desktop') )
    {
      console.log('caricamento dati profilo');
      // eslint-disable-next-line radix
      return this.ds.getDati(parseInt(localStorage.getItem('ID')))
      .pipe(
        last(),
        map(userdata =>  userdata)
      );
    }

  }

}
