/* eslint-disable @typescript-eslint/naming-convention */
import { environment } from './../environments/environment.prod';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {

  private azienda: string = localStorage.getItem('azienda');
  private api_url = '';

  constructor(private http: HttpClient, private authService: AuthService, private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.headers.get('skip')) {
      return next.handle(req);
    }

    const JWTToken = localStorage.getItem('JWTToken');
    console.log('JWTToken', JWTToken);
    //let JWTToken2 = 'xxx';

    const RefreshToken = localStorage.getItem('RefreshToken');
    //console.log( 'RefreshToken', RefreshToken);

    if (JWTToken === '' || RefreshToken === '' || JWTToken === null || RefreshToken === null) {
      this.router.navigateByUrl('/auth');
    }

    const new_req = req.clone({ setHeaders: { Authorization: `Bearer ${JWTToken}` } });

    switch (this.azienda) {
      case 'svs':
        this.api_url = environment.api_url_svs;
        break;
      case 'cts':
        this.api_url = environment.api_url_cts;
        break;
    }

    return next.handle(new_req)
      .pipe(
        map(resp => {
          // on Response
          if (resp instanceof HttpResponse) {
            // Do whatever you want with the response.
            //console.log('resp', resp);
            return resp;
          }
        }),
        catchError(err => {
          if (err instanceof HttpErrorResponse) {

            if (err.status === 401) {
              // 401 unauthorised user
              this.http.post<any>(this.api_url + 'login/refresh', { Token: { JWTToken, RefreshToken } })
                .subscribe(data => {
                  /* console.log('risultato RefreshToken', data);
                  console.log('response result', data.Result ); */
                  if (!data.Result) {
                    this.router.navigateByUrl('/auth');
                  }
                  else {
                    //Salvataggio nello store dei dati e si richiama la request con il token aggiornato
                    localStorage.setItem('JWTToken', data.Token.JWTToken);
                    localStorage.setItem('RefreshToken', data.Token.RefreshToken);

                    const New_JWTToken = data.Token.JWTToken;
                    //console.log('new JWTToken', New_JWTToken);

                    const last_req = req.clone({ setHeaders: { Authorization: `Bearer ${New_JWTToken}` } });
                    //console.log('last_req', last_req);
                    next.handle(last_req).subscribe();
                  }
                });
            }
          }
          return throwError(err);
        })
      );
  }
}
