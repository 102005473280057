/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { HttpClient } from '@angular/common/http';
import { MissioniService } from './missioni/missioni.service';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
// import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { Component, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { Capacitor } from '@capacitor/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { ToastController, Platform, AlertController } from '@ionic/angular';
import { App } from '@capacitor/app';
import { ActionPerformed, LocalNotifications, LocalNotificationSchema } from '@capacitor/local-notifications';
import { SqliteService } from './sqlite.service';
import { DetailService } from './detail.service';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { PushNotificationSchema, PushNotifications } from '@capacitor/push-notifications';
import { UpdateService } from './update.service';
// import { ForegroundService } from '@awesome-cordova-plugins/foreground-service/ngx';
//import { ForegroundService } from '@ionic-native/foreground-service/ngx';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})

export class AppComponent implements OnInit, OnDestroy {
  private authSub: Subscription;
  private status: ConnectionStatus;
  private listener: any;
  private listener_app: any;
  private numMissioni = 0;
  private countMissioni: number;
  private read_missioni = false;

  appVersion: any;
  appName: any;
  ok_sanificazione = false;
  ok_richieste = false;
  show_logout = false;

  @Output() data: any;

  intervalPeriod: number;

  minutes: number;
  subscription: Subscription;

  subscription_temp: Subscription;
  period_temp: number;

  // Handle Update Row Operation
  updateActive: boolean;
  to_update_item: any;

  private initPlugin: boolean;
  public isWeb = false;
  sync_missions = false;

  ok_prospetto = false;
  ok_network = true;

  private id_notification = 0;

  constructor(
    private router: Router,
    private authService: AuthService,
    private toastCtrl: ToastController,
    private backgroundMode: BackgroundMode,
    private platform: Platform,
    private missionService: MissioniService,
    private http: HttpClient,
    private sqliteService: SqliteService,
    private detailService: DetailService,
    private permissions: AndroidPermissions,
    private alertCtrl: AlertController,
    private updateAppService: UpdateService,
    // public foregroundService: ForegroundService
  ) {

    this.initializeApp();

  }

   initializeApp() {

    App.addListener('appStateChange', (result => { console.log('app state change' + JSON.stringify(result)); }));

    localStorage.setItem('getdatioffline', '0');

    // this.startService();

    if (!localStorage.getItem('show_alert') || localStorage.getItem('show_alert') === '0') {
      this.showAlert();
    }

    /* if (!this.platform.is('desktop')) {
      this.backgroundMode.setEnabled(true);
      this.backgroundMode.disableBatteryOptimizations();

      this.backgroundMode.setDefaults({
        title: 'EasyOBL',
        text: 'App attiva in background.',
        icon: 'icon_local_notifications',
        color: 'FFFFFF',
        resume: true,
        hidden: false,
        bigText: false,
        silent: false
      });
    } */
    /*this.backgroundMode.configure({
      title: 'EasyOBL',
      text: 'App attiva in background.',
      icon: 'icon_local_notifications',
      color: 'FFFFFF',
      resume: true,
      hidden: false,
      silent: false
    });*/

    if (!this.platform.is('desktop')) {

      LocalNotifications.createChannel({
        id: 'LocalNotificationAlerts',
        name: 'LocalNotificationAlerts',
        importance: 5,
        description: 'Local Notification Alerts',
        sound: 'sirena_ambulanza.wav',
        visibility: 1,
        vibration: true,
      });

      PushNotifications.register();
      /* PushNotifications.createChannel({
        id: 'PushNotificationAlerts',
        name: 'PushNotificationAlerts',
        importance: 5,
        description: 'Push Notification Alerts',
        sound: 'sirena_ambulanza.wav',
        visibility: 1,
        vibration: true,
      }); */

       // Show us the notification payload if the app is open on our device
       PushNotifications.addListener('pushNotificationReceived',
       (notification: PushNotificationSchema) => {
         const title = notification.title;
         const body = notification.body;
         console.log('Push received: ', JSON.stringify(notification));
         localStorage.setItem('data_notifications', notification.data);
         this.id_notification++;
         LocalNotifications.schedule({
           notifications: [
             {
               title,
               body,
               id: this.id_notification,
               smallIcon: 'obl_icona_18',
               //sound: 'suono_notification.wav',
               iconColor: '#7cc5f2',
               channelId: 'LocalNotificationAlerts',
               extra: notification.data
             }
           ]
         });
       }
     );
    /*  LocalNotifications.addListener('localNotificationReceived',
       (notification: LocalNotificationSchema) => {
         const title = notification.title;
         const body = notification.body;
         console.log('Push received: ', JSON.stringify(notification));
         localStorage.setItem('data_notifications', notification.extra);
         this.id_notification++;
         LocalNotifications.schedule({
           notifications: [
             {
               title,
               body,
               id: this.id_notification,
               smallIcon: 'obl_icona_18',
               //sound: 'suono_notification.wav',
               iconColor: '#7cc5f2',
               channelId: 'LocalNotificationAlerts',
             }
           ]
         });
       }
     ); */
      // Method called when tapping on a notification
      LocalNotifications.addListener('localNotificationActionPerformed',
      (notificationAction: ActionPerformed) => {
        console.log('Push action performed: ', JSON.stringify(notificationAction));
        //alert('Push action performed: ' + JSON.stringify(notificationAction));
        let message = JSON.stringify(notificationAction);
       //  this.showAlertPushData('Nuova Misssione', message );
        localStorage.setItem('push_notifications', message);
        localStorage.setItem('azienda_push',notificationAction.notification.extra.azienda);
        localStorage.setItem('barcode_push', notificationAction.notification.extra.barcode);
        localStorage.setItem('tipo_push', notificationAction.notification.extra.tipo);
        localStorage.setItem('targa_push',notificationAction.notification.extra.targa);
        localStorage.setItem('loadpush', '1');
        if( localStorage.getItem('ID') && localStorage.getItem('ID') !== '' ){
          this.router.navigateByUrl('/nuova-missione');
        }
      }
    );
      /*  // Method called when tapping on a notification
       PushNotifications.addListener('pushNotificationActionPerformed',
       (notification: ActionPerformed) => {
         console.log('Push action performed: ', JSON.stringify(notification));
        alert('Push action performed: ' + JSON.stringify(notification));
         let message = JSON.stringify(notification);
        //  this.showAlertPushData('Nuova Misssione', message );
         localStorage.setItem('push_notifications', message);
         localStorage.setItem('azienda_push',notification.notification.data.azienda);
         localStorage.setItem('barcode_push', notification.notification.data.barcode);
         localStorage.setItem('tipo_push', notification.notification.data.tipo);
         localStorage.setItem('targa_push',notification.notification.data.targa);
         localStorage.setItem('loadpush', '1');

          this.router.navigateByUrl('/nuova-missione');
       }
     ); */
    }

    this.platform.ready().then(async () => {

      // this.updateAppService.checkForUpdate();

      this.sqliteService.initializePlugin().then(ret => {
        this.initPlugin = ret;
        console.log('>>>> in App  this.initPlugin ' + this.initPlugin);
        this.createDbTable();

        const p: string = this.sqliteService.platform;
        console.log(`plaform ${p}`);
      });

    });
  }

  setShowLogout(result: any) {
    //access result here
    this.show_logout = result;
  }

  setShowMaterialeSanificazione(result: any) {
    //access result here
    const azienda = localStorage.getItem('azienda');
    if (azienda === 'svs') {
      if (this.authService.utente.toLowerCase() === 'fcarnovale' || this.authService.utente.toLowerCase() === 'mcolini'
        || this.authService.utente.toLowerCase() === 'fmalara' || this.authService.utente.toLowerCase() === 'emancina') {
        this.ok_sanificazione = true;
      }
    }
    else {
      this.ok_sanificazione = false;
    }
  }

  setShowMateriale(result: any) {
    //access result here
    const azienda = localStorage.getItem('azienda');
    console.log('azienda setShowMateriale', azienda);
    if (azienda === 'svs') {
      this.ok_richieste = true;
    }
    else {
      this.ok_richieste = false;
    }
  }

  setSyncMissions(result: any) {
    /* let sync = localStorage.getItem('sync');
    console.log('sync missioni', sync);
    if( sync === '1' ){ */
    this.sync_missions = result;

    console.log('setSyncMissions', result);
    /* }
    else{
      this.sync_missions = false;
    }
 */
  }

  setShowProspetto(result: any) {
    const azienda = localStorage.getItem('azienda');
    if (azienda === 'svs') {
      this.ok_prospetto = true;
    }
    else {
      this.ok_prospetto = false;
    }
  }

  setNetwork(result: any) {
    //access result here
    this.ok_network = result;
  }


  /* startService() {
    // Notification importance is optional, the default is 1 - Low (no sound or vibration)
    this.foregroundService.start('EsayOBL', 'Rilevamento Temperature Contenitori','obl_icona_18');
   }

  stopService() {
   // Disable the foreground service
   this.foregroundService.stop();
  } */

  async ngOnInit() {
    console.log('ngoninit');

    this.platform.ready().then(async () => {
      console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxx');
      await this.updateAppService.checkForUpdate().then( async () =>{
        //localStorage.setItem('readMissioni', '0');
        localStorage.setItem('missione_interna', '0');
        localStorage.setItem('missione_esterna', '0');

        if (this.platform.is('desktop')) {
          console.log('remove targhe da localstorage');
          localStorage.removeItem('targhe');
        }

        //Scansione datalogger
        //Lettura da localstorage di "gestionemissioni"; TODO MONICA
        // this.startService();

        /* if( !this.platform.is('desktop') ){

          console.log('loop per scansione temperatura');
          this.period_temp = 60 * 1000;

          let devices = ['D0:23:51:9E:45:D3','EF:13:13:D4:49:5B','F7:B2:F2:A4:C2:9B','DA:75:8E:91:39:BF','F5:96:D6:AC:85:94','D6:81:0D:1A:AC:E0'];
          devices.forEach(id_device => {

            this.subscription_temp = timer(0, this.period_temp)
            .pipe(
              switchMap(() => {
                console.log('scan temperatura');

                //Richiamiamo la scansione sono se ci sono missioni attive  TODO MONICA

                return this.missionService.scan(id_device);
              }),
              filter(data => data !== undefined)
            )
            .subscribe( data => {
                console.log('data', data);
            });
          });
        } */

        // this.stopService();

        this.authService.obLogout.subscribe((result) => {
          this.setShowLogout(result);
        });

        this.authService.obMaterialeSanificazione.subscribe((result) => {
          this.setShowMaterialeSanificazione(result);
        });

        this.authService.obMateriale.subscribe((result) => {
          this.setShowMateriale(result);
        });

        this.missionService.obSyncMissions.subscribe((result) => {
          this.setSyncMissions(result);
        });

        this.authService.obProspetto.subscribe((result) => {
          this.setShowProspetto(result);
        });

        if (Capacitor.isPluginAvailable('Network')) {
          await this.getStatus();
          // alert('network ' + JSON.stringify(this.status));
          if( this.status.connected ){
            localStorage.setItem('networkok','1');
            this.setNetwork(true);
          }
          else{
            localStorage.setItem('networkok','0');
            this.setNetwork(false);
          }
          this.startListenNetwork();
        }

        if (Capacitor.isPluginAvailable('App')) {
          App.getInfo().then(info => {
            this.appVersion = info.version;
            return this.appVersion;
          },
            err => {
              this.appVersion = 'web';
            });
        } else {
          this.appVersion = 'web';
        }

        if (Capacitor.isPluginAvailable('App')) {
          App.getInfo().then(info => {
            this.appName = info.name;
            return this.appName;
          },
            err => {
              this.appName = 'OBL';
            });
        } else {
          this.appName = 'OBL';
        }

        if (this.authService.userIsAuthenticated)
          {this.show_logout = true;}
        else
          {this.show_logout = false;}

        if (this.authService.userIsAuthenticated
          && (this.authService.utente.toLowerCase() === 'fcarnovale' || this.authService.utente.toLowerCase() === 'mcolini'
            || this.authService.utente.toLowerCase() === 'fmalara' || this.authService.utente.toLowerCase() === 'emancina')) {
          this.ok_sanificazione = true;
        }
        else {
          this.ok_sanificazione = false;
        }

        if (localStorage.getItem('utente') !== '' && localStorage.getItem('utente') !== null
          && (localStorage.getItem('utente').toLowerCase() === 'fcarnovale' || localStorage.getItem('utente').toLowerCase() === 'mcolini'
            || localStorage.getItem('utente').toLowerCase() === 'fmalara' || localStorage.getItem('utente').toLowerCase() === 'emancina')) {
          this.ok_sanificazione = true;
        }
        else {
          this.ok_sanificazione = false;
        }

        if (localStorage.getItem('azienda') === 'svs') {
          this.ok_richieste = true;
          this.ok_prospetto = true;
        }
        else {
          this.ok_richieste = false;
          this.ok_prospetto = false;
        }

        //this.createDbTable();

      } );
    } );


  }

  ionViewDidEnter() {
    if (this.platform.is('desktop')) {
      console.log('remove targhe da localstorage');
      localStorage.removeItem('targhe');
    }

    this.authService.obLogout.subscribe((result) => {
      this.setShowLogout(result);
    });

    this.authService.obMaterialeSanificazione.subscribe((result) => {
      this.setShowMaterialeSanificazione(result);
    });

    this.authService.obMateriale.subscribe((result) => {
      this.setShowMateriale(result);
    });

    this.missionService.obSyncMissions.subscribe((result) => {
      this.setSyncMissions(result);
    });

    this.authService.obProspetto.subscribe((result) => {
      this.setShowProspetto(result);
    });

    if (Capacitor.isPluginAvailable('Network')) {
      this.getStatus();
      this.startListenNetwork();
    }

    if (Capacitor.isPluginAvailable('App')) {
      App.getInfo().then(info => {
        this.appVersion = info.version;
        return this.appVersion;
      },
        err => {
          this.appVersion = 'web';
        });
    } else {
      this.appVersion = 'web';
    }

    if (Capacitor.isPluginAvailable('App')) {
      App.getInfo().then(info => {
        this.appName = info.name;
        return this.appName;
      },
        err => {
          this.appName = 'OBL';
        });
    } else {
      this.appName = 'OBL';
    }

    if (this.authService.userIsAuthenticated)
      {this.show_logout = true;}
    else
      {this.show_logout = false;}

    if (this.authService.userIsAuthenticated
      && (this.authService.utente.toLowerCase() === 'fcarnovale' || this.authService.utente.toLowerCase() === 'mcolini'
        || this.authService.utente.toLowerCase() === 'fmalara' || this.authService.utente.toLowerCase() === 'emancina')) {
      this.ok_sanificazione = true;
    }

    if (localStorage.getItem('azienda') === 'svs') {
      console.log('check azienda');
      this.ok_richieste = true;
      this.ok_prospetto = true;
    }
    else {
      this.ok_richieste = false;
      this.ok_prospetto = false;
    }

    //this.createDbTable();
  }

  async createDbTable() {
    let db: SQLiteDBConnection;
    let res: any;
    let res2: any;
    let res3: any;
    let res4: any;
    let res5: any;
    let res6: any;
    let res7: any;
    let res8: any;
    let res9: any;
    let res10: any;
    let res11: any;
    let res12: any;
    let res13: any;
    let res14: any;
    let res15: any;
    let query: string;

    try {
      console.log(`going to create a connection`);
      /*  db = await this.sqliteService.createConnection("missioni",false,"no-encryption", 1);
       console.log(`db ${JSON.stringify(db)}`)
       await db.open();
       console.log(`after db.open`) */

      db = await this.missionService.SetConnection();

      /*  let res0: any;
       query='drop table testata_prospetto';
       res0=await db.execute(query); */

      query = `CREATE TABLE IF NOT EXISTS testata (esercizio TEXT NOT NULL,registro TEXT NOT NULL,numero REAL NOT NULL,causale TEXT NOT NULL,causale_descr TEXT NOT NULL,id_tipomezzo INTEGER NOT NULL,pdi_partenza REAL NOT NULL,pdi_destinazione REAL NOT NULL,id_automezzo TEXT NOT NULL,id_assegnazione TEXT NOT NULL,descrizione TEXT NOT NULL, trasporto_interno TEXT NOT NULL,PRIMARY KEY (esercizio,registro,numero ));`;
      console.log(`query testata ${query}`);
      res = await db.execute(query);
      console.log(`res: ${JSON.stringify(res)}`);

      query = `CREATE TABLE IF NOT EXISTS prenotazioni (esercizio TEXT NOT NULL,registro TEXT NOT NULL,numero REAL NOT NULL,numero_riga INTEGER NOT NULL,data_viaggio TEXT NOT NULL,ora_viaggio TEXT NOT NULL,id_automezzo TEXT NOT NULL,stato_richiesta INTEGER NOT NULL,id_assegnazione TEXT NOT NULL,PRIMARY KEY  (esercizio,registro,numero,numero_riga));`;
      console.log(`query prenotazioni ${query}`);
      res2 = await db.execute(query);
      console.log(`res2: ${JSON.stringify(res2)}`);

      query = `CREATE TABLE IF NOT EXISTS itinerari (esercizio TEXT NOT NULL,registro TEXT NOT NULL,numero REAL NOT NULL,numero_riga_itinerario INTEGER NOT NULL,tipo_operazione INTEGER NOT NULL,orario_arrivo TEXT NOT NULL,orario_partenza TEXT NOT NULL,pdi_lat TEXT NULL,pdi_lng TEXT NULL,pdi_descr1 TEXT NULL,pdi_descr2 TEXT NULL,pdi_descr3 TXT NULL,pdi_codice INTEGER NOT NULL,quantita INTEGER NOT NULL DEFAULT 0, articolo_barcode TEXT NOT NULL,articolo_descrizione TEXT NOT NULL,articolo_codice INTEGER NOT NULL, tipo_contenitore INTEGER NULL, altezza INTEGER NULL, larghezza INTEGER NULL, profondita INTEGER NULL, peso INTEGER NULL,temp_max INTEGER NULL, temp_min INTEGER NULL, ordine INTEGER NOT NULL,  PRIMARY KEY  (esercizio,registro,numero,numero_riga_itinerario, tipo_operazione));`;
      console.log(`query itinerari ${query}`);
      res3 = await db.execute(query);
      console.log(`res3: ${JSON.stringify(res3)}`);

      query = `CREATE TABLE IF NOT EXISTS testata_prospetto (esercizio TEXT NOT NULL,registro TEXT NOT NULL,numero REAL NOT NULL,causale TEXT NOT NULL,causale_descr TEXT NOT NULL,id_tipomezzo INTEGER NOT NULL,pdi_partenza REAL NOT NULL,pdi_destinazione REAL NOT NULL,id_automezzo TEXT NOT NULL,id_assegnazione TEXT NOT NULL,descrizione TEXT NOT NULL, trasporto_interno TEXT NOT NULL, PRIMARY KEY (esercizio,registro,numero ));`;
      console.log(`query testata ${query}`);
      res4 = await db.execute(query);
      console.log(`res4: ${JSON.stringify(res4)}`);

      query = `CREATE TABLE IF NOT EXISTS prenotazioni_prospetto (esercizio TEXT NOT NULL,registro TEXT NOT NULL,numero REAL NOT NULL,numero_riga INTEGER NOT NULL,data_viaggio TEXT NOT NULL,ora_viaggio TEXT NOT NULL,id_automezzo TEXT NOT NULL,stato_richiesta INTEGER NOT NULL,id_assegnazione TEXT NOT NULL,PRIMARY KEY  (esercizio,registro,numero,numero_riga));`;
      console.log(`query prenotazioni ${query}`);
      res5 = await db.execute(query);
      console.log(`res5: ${JSON.stringify(res5)}`);

      query = `CREATE TABLE IF NOT EXISTS itinerari_prospetto (esercizio TEXT NOT NULL,registro TEXT NOT NULL,numero REAL NOT NULL,numero_riga_itinerario INTEGER NOT NULL,tipo_operazione INTEGER NOT NULL,orario_arrivo TEXT NOT NULL,orario_partenza TEXT NOT NULL,pdi_lat TEXT NULL,pdi_lng TEXT NULL,pdi_descr1 TEXT NULL,pdi_descr2 TEXT NULL,pdi_descr3 TXT NULL,pdi_codice INTEGER NOT NULL,quantita INTEGER NOT NULL DEFAULT 0, articolo_barcode TEXT NOT NULL,articolo_descrizione TEXT NOT NULL,articolo_codice INTEGER NOT NULL, tipo_contenitore INTEGER NULL, altezza INTEGER NULL, larghezza INTEGER NULL, profondita INTEGER NULL, peso INTEGER NULL,temp_max INTEGER NULL, temp_min INTEGER NULL, ordine INTEGER NOT NULL,  PRIMARY KEY  (esercizio,registro,numero,numero_riga_itinerario, tipo_operazione));`;
      console.log(`query itinerari ${query}`);
      res6 = await db.execute(query);
      console.log(`res6: ${JSON.stringify(res6)}`);

      query = `CREATE TABLE IF NOT EXISTS richiesta_prodotti (esercizio TEXT NOT NULL,registro TEXT NOT NULL,numero REAL NOT NULL,numero_riga_itinerario INTEGER NOT NULL, numero_riga_missione INTEGER NOT NULL, numero_riga_stato INTEGER NOT NULL, stato INTEGER NOT NULL, id_prodotto TEXT NULL, id_contenitore TEXT NULL, id_cella TEXT NULL, temperatura REAL NULL, tipo_operazione INTEGER NOT NULL, data_comunicazione TEXT NOT NULL, ora_comunicazione TEXT NOT NULL, lat TEXT NULL, lng TEXT NULL, ordine INTEGER NOT NULL, PRIMARY KEY  (esercizio,registro,numero,numero_riga_itinerario, numero_riga_missione, numero_riga_stato, tipo_operazione));`;
      console.log(`query itinerari ${query}`);
      res7 = await db.execute(query);
      console.log(`res7: ${JSON.stringify(res7)}`);

      query = `CREATE TABLE IF NOT EXISTS temperature (id_assegnazione TEXT NOT NULL,id_contenitore TEXT NOT NULL,data TEXT NOT NULL,temperatura TEXT NOT NULL,PRIMARY KEY (id_assegnazione,id_contenitore,data,temperatura ));`;
      console.log(`query temperature ${query}`);
      res8 = await db.execute(query);
      console.log(`res8: ${JSON.stringify(res8)}`);

      query = `CREATE TABLE IF NOT EXISTS rest_service_fifo (url_metodo TEXT NOT NULL,type TEXT NOT NULL,params TEXT NOT NULL, headers TEXT NULL, data TEXT NOT NULL,ora TEXT NOT NULL,PRIMARY KEY (url_metodo,type,params,data,ora));`;
      console.log(`query rest_service_fifo ${query}`);
      res9 = await db.execute(query);
      console.log(`res9: ${JSON.stringify(res9)}`);

      //Le delete ora vanno qua per la fase di test, poi andranno spostate nella destroy della app TODO
      // await this.missionService.resetLocalDatabase(db);

      if (localStorage.getItem('sync-missions') && localStorage.getItem('sync-missions') === '1') {
        this.showAlertLogout();
      }
      else {

        //Cancellazione database
        await this.missionService.resetLocalDatabase(db);
        localStorage.setItem('getdatioffline', '0');
      }
      // await db.close();
      /*  await this.sqliteService.closeConnection('missioni');
       console.log('after connection close'); */
    } catch (err) {
      console.log(`Error: ${err}`);
      this.initPlugin = false;
    }
  }

  async getStatus() {
    try {
      this.status = await Network.getStatus();
      if( this.status.connected ){
        localStorage.setItem('networkok','1');
      }
      else{
        localStorage.setItem('networkok','0');
      }
    } catch (e) { console.log('Error', e); }
  }

  startListenNetwork() {
    this.listener = Network.addListener('networkStatusChange', (status) => {
      if (!status.connected) {
        localStorage.setItem('networkok', '0');
        this.setNetwork(false);
        this.presentToast('La tua connessione Internet sembra essere offline. Puoi continuare ad utilizzare la app ma ricordati di sincronizzare i dati quando avrai nuovamente una connessione attiva.');
      }
      else {
        localStorage.setItem('networkok', '1');
        console.log('ritorno della connessione', this.router.routerState.snapshot.url);
        this.setNetwork(true);
        if (this.router.routerState.snapshot.url === '/auth') {
          // this.router.navigateByUrl('/auth');

        }
        /* if( this.router.routerState.snapshot.url === '/sync-missions'){
          this.router.

        } */

        // window.location.reload();
        // this.presentToast('Connession ok!' +  this.router.routerState.snapshot.url);
      }
    });
  }

  stopListenNetwork() {
    if (this.listener) {this.listener.remove();}
  }

  async presentToast(msg) {
    const toast = await this.toastCtrl.create({ message: msg, duration: 3000, position: 'bottom' });

    toast.present();
  }

  async onLogout() {

    //Se ci sono missioni da sincronizzare non facciamo fare il logout
    if (localStorage.getItem('sync-missions') && localStorage.getItem('sync-missions') === '1') {
      this.showAlertLogout();
    }
    else {

      //Cancellazione database
      let db: SQLiteDBConnection;
      db = await this.missionService.SetConnection();
      this.missionService.resetLocalDatabase(db).then(() => {
        this.authService.logout();
        this.show_logout = false;
        this.ok_sanificazione = false;
        this.ok_richieste = false;
        localStorage.setItem('getdatioffline', '0');

        this.router.navigateByUrl('/auth');
      });

    }
  }

  ionViewDidLeave() {
    console.log('app components ionViewDidLeave');
    this.presentToast('app components ionViewDidLeave');
  }

  ionViewWillLeave() {
    console.log('app components ionViewWillLeave');
    console.log('app components ionViewwILLLeave');
    if (Capacitor.isPluginAvailable('Network')) {
      this.stopListenNetwork();
    }
    this.show_logout = false;
  }

  ngOnDestroy() {
    console.log('app components ngOnDestroy');
    //Se ci sono missioni da sincronizzare non facciamo fare il logout
    if (localStorage.getItem('sync-missions') && localStorage.getItem('sync-missions') === '1') {
      this.showAlertLogout();
    }
    else {
      if (Capacitor.isPluginAvailable('Network')) {
        this.stopListenNetwork();
      }

      if (!this.authSub) {
        this.authSub.unsubscribe();
      }
      this.show_logout = false;
      //this.stopService();
    }
  }

  private showAlert() {
    this.alertCtrl
      .create({
        header: 'Avviso',
        message: 'EasyOBL utilizza la localizzazione in background per migliorare le funzionalità di tracciamento nei prelievi e consegna dei materiali. La localizzazione è attiva anche quando EasyOBL non è in uso. EasyOBL utilizza anche la fotocamera per la scansione dei QRCode delle targhe, dei prodotti e dei contenitori utilizzati nel trasporto.',
        buttons: [
          {
            text: 'Ok',
            handler: () => {
              if (Capacitor.isPluginAvailable('Geolocation')) {
                this.permissions.checkPermission(this.permissions.PERMISSION.ACCESS_COARSE_LOCATION).then(
                  result => console.log('Has permission?', result.hasPermission)
                );

                this.permissions.requestPermissions([this.permissions.PERMISSION.ACCESS_COARSE_LOCATION, this.permissions.PERMISSION.ACCESS_FINE_LOCATION]);
              }

              this.permissions.checkPermission(this.permissions.PERMISSION.CAMERA).then(
                result => console.log('Has permission?', result.hasPermission),
                err => this.permissions.requestPermission(this.permissions.PERMISSION.CAMERA)
              );

              this.permissions.requestPermissions([this.permissions.PERMISSION.CAMERA, this.permissions.PERMISSION.GET_ACCOUNTS]);

              localStorage.setItem('show_alert', '1');
            }
          }
        ],
      })
      .then(alertEl => alertEl.present());
  }

  private showAlertLogout() {
    this.alertCtrl
      .create({
        header: 'Avviso',
        message: 'Sono presenti delle missioni da sincronizzare.',
        buttons: [
          {
            text: 'Okay',
            handler: () => {
              this.router.navigateByUrl('/sync-missions');
            }
          }
        ],
      })
      .then(alertEl => alertEl.present());
  }

  /* private showAlertPushData(header: string, message: string) {
    this.alertCtrl
      .create({
        header,
        message,
        buttons: ['Okay']
      })
      .then(alertEl => alertEl.present());
  } */
}


