/* eslint-disable @typescript-eslint/naming-convention */
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseAndroidAPIKey: 'AIzaSyAkPycysPnidlgaBZqEU-2FVtTYozQtcWE',
  firebaseWebAPIKey: 'AIzaSyANR15IINthhw3_EsGjyn_5n-DpjMjwtvo',
  /* api_url_svs: 'https://api.trasportosangueorgani.it/',
  api_url_cts: 'https://apitest.trasportosangueorgani.it/' */
  api_url_svs: 'https://api2.trasportosangueorgani.it/',
  api_url_cts: 'https://api.consorziotoscanaservizi.it/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
