/* eslint-disable @typescript-eslint/naming-convention */
export class User {
    constructor(
      public userid: number,
      public utente: string,
      public nome: string,
      public cognome: string,
      public sesso: string,
      public stato: string,
      //public codicefiscale: string,
      public JWTToken: string,
      public RefreshToken: string
    ) {}


  }
