import { AlertController, AlertOptions } from '@ionic/angular';
/* eslint-disable @typescript-eslint/no-shadow */
import { HttpClient, HttpHeaders } from '@angular/common/http';
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Platform } from '@ionic/angular';
import { Browser } from '@capacitor/browser';

interface AppUpdate{
  current: string;
  enabled: boolean;
  msg?: {
    title: string;
    msg: string;
    btn: string;
  };
  majorMsg?: {
    title: string;
    msg: string;
    btn: string;
  };
  minorMsg?: {
    title: string;
    msg: string;
    btn: string;
  };
}

@Injectable({
  providedIn: 'root'
})

export class UpdateService {
  appVersion: string;
  serverAppVersion: string;
  updateFileVersion= 'https://demo.trasportosangueorgani.it/ws/version.php';

  constructor(private http: HttpClient, private alertCtrl: AlertController) { }

  async checkForUpdate(){

    this.http.get(this.updateFileVersion ).subscribe( ( info: AppUpdate ) => {
      console.log('info', JSON.stringify(info));
      if( info.enabled ){
        console.log('1');
        // if (Capacitor.isPluginAvailable('App')) {
        console.log('2');
        App.getInfo().then(
          data => {
            console.log('result app version', JSON.stringify(data));
            this.appVersion = data.version;
            this.appVersion.replace('beta','');
            console.log('app version dentro', this.appVersion);

            console.log('app version', this.appVersion);
            const splittedVersionNumber = this.appVersion.split('.');
            console.log('splitted version', splittedVersionNumber);
            this.serverAppVersion = info.current;
            console.log('server version', this.serverAppVersion);
            const splittedServerVersionNumber = this.serverAppVersion.split('.');
            console.log('splitted server', splittedServerVersionNumber);
            if( splittedServerVersionNumber[0] > splittedVersionNumber[0] ){
              console.log('sono qua');
              this.showAlert(info.majorMsg.title,info.majorMsg.msg, info.majorMsg.btn, false );
            }
            else{
              if( splittedServerVersionNumber[1] > splittedVersionNumber[1] ){
                console.log('sono qua');
                this.showAlert(info.majorMsg.title,info.majorMsg.msg, info.majorMsg.btn, false );
              }
              else{
                if( splittedServerVersionNumber[2] > splittedVersionNumber[2] ){
                  console.log('sono qua');
                  this.showAlert(info.majorMsg.title,info.majorMsg.msg, info.majorMsg.btn, false );
                }
              }
            }

          });
        // }


      }
    },
    (error) => {
      console.error('errore', JSON.stringify(error));
    }
    );
  }

  openAppStoreEntry(){
    //this.market.open('com.svslivorno.obl');
    window.location.href = 'market://details?id=com.svslivorno.obl';
    // Browser.open({url:'https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.svslivorno.obl'});
  }

  showAlert( header, message, buttonText = '', backdropDismiss=false ){

    const buttons: any=[];

    if( buttonText !== '' ){
      buttons.push({
        text: buttonText,
        cssClass: 'app-version-alert-btn',
        handler: () => {
          this.openAppStoreEntry();
        }
      });
    }

    this.alertCtrl
    .create({
      header,
      message,
      buttons,
      backdropDismiss
    })
    .then(alertEl => alertEl.present());
  }
}

