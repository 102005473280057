/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable max-len */
import { environment } from './../../environments/environment';
import { AnagraficaUtenteIdentifierModel, AnagraficaUtenteModel, CredenzialiUtenteModel, ProvinciaModel, ComuneModel, IndirizzoModel,
         StradarioIdentifierModel,  DatiFiscaliModel, RecapitoBaseModel, RecapitoIdentifierModel, OperatoreBaseModel, OperatoreIdentifierModel,
         PatenteModel, TipoRecapito, NazioneModel, SezioneBaseModel} from './anagrafica-utente.model';
import { AlertController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { last, map, take, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CoordinateModel } from '../missioni/missione-itinerario.model';

interface Profilo{
  Result: boolean;
  AnagraficaUtente: AnagraficaUtenteModel;
}

interface FotoProfilo{
  Result: boolean;
  FotoProfilo: string;
}

@Injectable({
  providedIn: 'root'
})

export class DatiService {

  private _myData = new BehaviorSubject<AnagraficaUtenteModel>(null);
  private _myFoto = new BehaviorSubject<SafeUrl>(null);

  private api_url = '';
  private Immagine = '';

  private azienda  = '';

  get data() {
    return this._myData.asObservable();
  }

  get codicefiscale(){
    return this._myData.asObservable().pipe(
      map(dati => {
        if (dati) {
          return dati.DatiFiscali.CodiceFiscale;
        } else {
          return null;
        }
      })
    );
  }

  get sezione(){
    return this._myData.asObservable().pipe(
      map(dati => {
        if (dati) {
          return dati.Operatore.Sezione.Descrizione;
        } else {
          return null;
        }
      })
    );
  }

  constructor(private http: HttpClient, private alertCtrl: AlertController, private domSanitizer: DomSanitizer) { }

  getDati(id: number)
  {
     /*  this.getFotoProfilo(id)
      .subscribe( img =>
        { //console.log( 'img', img );
          this.Immagine = img;
        }); */

      this.azienda = localStorage.getItem('azienda');
      console.log('azienda dentro getdati', this.azienda);
      switch(this.azienda)
      {
        case 'svs':
          this.api_url = environment.api_url_svs;
          break;
        case 'cts':
          this.api_url = environment.api_url_cts ;
          break;
      }
      console.log('getdati', this.api_url);
    return this.http
      .post<Profilo>(
        this.api_url + 'user/GetProfilo',
          { AnagraficaUtenteIdentifier: { ID: id } }
      ).pipe(
        last(),
        map(resData => {
          console.log(resData);

          let DatiProfiloUtente: AnagraficaUtenteModel;
          if( resData.Result )
          {
            let Identifier = new AnagraficaUtenteIdentifierModel(
              resData.AnagraficaUtente.Identifier.ID
            );

            let Credenziali = new CredenzialiUtenteModel(
              resData.AnagraficaUtente.Credenziali.Email,
              resData.AnagraficaUtente.Credenziali.Password
            );

            let ProvinciaNascita = new ProvinciaModel(
              resData.AnagraficaUtente.ProvinciaNascita.Denominazione,
              resData.AnagraficaUtente.ProvinciaNascita.Sigla
            );

            let ComuneNascita = new ComuneModel(
              resData.AnagraficaUtente.ComuneNascita.CodiceCastale,
              resData.AnagraficaUtente.ComuneNascita.Denominazione
            );

            let ComuneResidenza = new ComuneModel(
              resData.AnagraficaUtente.Residenza.Comune.CodiceCastale,
              resData.AnagraficaUtente.Residenza.Comune.Denominazione
            );

            let ProvinciaResidenza = new ProvinciaModel(
              resData.AnagraficaUtente.Residenza.Provincia.Denominazione,
              resData.AnagraficaUtente.Residenza.Provincia.Sigla
            );

            let NazioneResidenza: NazioneModel;
            if( resData.AnagraficaUtente.Residenza.Nazione)
            {
              NazioneResidenza = new NazioneModel
              (
                resData.AnagraficaUtente.Residenza.Nazione.CodiceIstat,
                resData.AnagraficaUtente.Residenza.Nazione.Denominazione
              );
            }
            else
            {
              NazioneResidenza = null;
            }
            let CoordinateResidenza: CoordinateModel;
            if( resData.AnagraficaUtente.Residenza.Coordinate )
            {
                CoordinateResidenza = new CoordinateModel(
                  resData.AnagraficaUtente.Residenza.Coordinate.Latitudine,
                  resData.AnagraficaUtente.Residenza.Coordinate.Longitudine
                );
            }
            else
            {
              CoordinateResidenza = null;
            }

            let StradarioResidenzaIdentifier = new StradarioIdentifierModel(
              resData.AnagraficaUtente.Residenza.StradarioIdentifier.ID,
            );

            let Residenza = new IndirizzoModel(
              StradarioResidenzaIdentifier,
              resData.AnagraficaUtente.Residenza.Indirizzo,
              resData.AnagraficaUtente.Residenza.Civico,
              resData.AnagraficaUtente.Residenza.CAP,
              resData.AnagraficaUtente.Residenza.Localita,
              ComuneResidenza,
              ProvinciaResidenza,
              NazioneResidenza,
              CoordinateResidenza
            );

            let StradarioDomiciioIdentifier = new StradarioIdentifierModel(
              resData.AnagraficaUtente.Domicilio.StradarioIdentifier.ID
            );

            let ComuneDomicilio = new ComuneModel(
              resData.AnagraficaUtente.Domicilio.Comune.CodiceCastale,
              resData.AnagraficaUtente.Domicilio.Comune.Denominazione
            );

            let ProvinciaDomicilio = new ProvinciaModel(
              resData.AnagraficaUtente.Domicilio.Provincia.Sigla,
              resData.AnagraficaUtente.Domicilio.Provincia.Denominazione
            );

            let NazioneDomicilio: NazioneModel;
            if( resData.AnagraficaUtente.Domicilio.Nazione )
            {
              NazioneDomicilio = new NazioneModel(
                resData.AnagraficaUtente.Domicilio.Nazione.CodiceIstat,
                resData.AnagraficaUtente.Domicilio.Nazione.Denominazione
              );
            }
            else
            {
              NazioneDomicilio = null;
            }

            let CoordinateDomicilio: CoordinateModel;
            if( resData.AnagraficaUtente.Domicilio.Coordinate )
            {
              CoordinateDomicilio = new CoordinateModel(
                resData.AnagraficaUtente.Domicilio.Coordinate.Latitudine,
                resData.AnagraficaUtente.Domicilio.Coordinate.Longitudine
              );
            }

            let Domicilio = new IndirizzoModel(
              StradarioDomiciioIdentifier,
              resData.AnagraficaUtente.Domicilio.Indirizzo,
              resData.AnagraficaUtente.Domicilio.Civico,
              resData.AnagraficaUtente.Domicilio.CAP,
              resData.AnagraficaUtente.Domicilio.Localita,
              ComuneDomicilio,
              ProvinciaDomicilio,
              NazioneDomicilio,
              CoordinateDomicilio
            );
            let DatiFiscali = new DatiFiscaliModel(
              resData.AnagraficaUtente.DatiFiscali.PartitaIVA,
              resData.AnagraficaUtente.DatiFiscali.CodiceFiscale
            );
            let Recapiti: RecapitoBaseModel[] = [];

            resData.AnagraficaUtente.Recapiti.forEach( item => {

              console.log('item',item);
                let RecapitoIdentifier = new RecapitoIdentifierModel(
                  item.Identifier.ID
                );

                let DescrizioneLabelRecapito = '';
                DescrizioneLabelRecapito = TipoRecapito[item.TipoRecapito];

                if( item.Descrizione !== '' )
                {
                  DescrizioneLabelRecapito = DescrizioneLabelRecapito + ' ' + item.Descrizione;
                }

                let Recapito = new RecapitoBaseModel(
                  RecapitoIdentifier,
                  DescrizioneLabelRecapito,
                  item.Prefisso,
                  item.Telefono,
                  item.TipoRecapito
                );

                Recapiti.push(Recapito);
              }
            );

            let OperatoreIdentifier = new OperatoreIdentifierModel(
              resData.AnagraficaUtente.Operatore.OperatoreIdentifier.ID
            );

            let OperatorePatente = new PatenteModel(
                resData.AnagraficaUtente.Operatore.Patente.Patente,
                resData.AnagraficaUtente.Operatore.Patente.PatenteNumero,
                resData.AnagraficaUtente.Operatore.Patente.PatenteScadenzaData
            );

            let Sezione = new SezioneBaseModel(
              resData.AnagraficaUtente.Operatore.Sezione.Identifier,
              resData.AnagraficaUtente.Operatore.Sezione.Descrizione,
              false,
              null,
              0
            );

            let Operatore = new OperatoreBaseModel(
              OperatoreIdentifier,
              resData.AnagraficaUtente.Operatore.Badge,
              resData.AnagraficaUtente.Operatore.Matricola,
              this.formatDate(resData.AnagraficaUtente.Operatore.DataInizioAttivita),
              this.formatDate(resData.AnagraficaUtente.Operatore.DataTermineAttivita),
              Sezione,
              resData.AnagraficaUtente.Operatore.Tipologia,
              resData.AnagraficaUtente.Operatore.Annotazione,
              OperatorePatente
            );

            localStorage.setItem('sezione',resData.AnagraficaUtente.Operatore.Sezione.Descrizione);

            if( this.Immagine === '' && localStorage.getItem('immagine') !== '' )
            {
              this.Immagine = localStorage.getItem('immagine');

            }
            DatiProfiloUtente = new AnagraficaUtenteModel (
              Identifier,
              Credenziali,
              resData.AnagraficaUtente.Nome,
              resData.AnagraficaUtente.Cognome,
              resData.AnagraficaUtente.Stato,
              this.formatDate(resData.AnagraficaUtente.DataNascita),
              resData.AnagraficaUtente.Sesso,
              ProvinciaNascita,
              ComuneNascita,
              Residenza,
              Domicilio,
              DatiFiscali,
              Recapiti,
              resData.AnagraficaUtente.OperatoreTrasporti,
              resData.AnagraficaUtente.OperatoreGenerico,
              Operatore,
              this.Immagine,
              this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.Immagine}`)
            );
          }
          else
          {
            //Messaggio errore
            this.showAlert('ERRORE');
          }

          console.log('dati profilo', DatiProfiloUtente);
          return DatiProfiloUtente;
        }),
        tap(data => {
          this.getFotoProfilo(id)
          .subscribe( img =>
            { //console.log( 'img', img );
              this.Immagine = img;
              data.Immagine = this.Immagine;
              data.ImmagineSafe = this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${this.Immagine}`);
            });
          this._myData.next(data);
          // this._myFoto.next(this.Immagine);
        })
      );
  }

  getFotoProfilo(id: number)
  {
    console.log('getFotoProfilo');
    let fotoProfilo: string;
    this.azienda = localStorage.getItem('azienda');
    switch(this.azienda)
    {
      case 'svs':
        this.api_url = environment.api_url_svs;
        break;
      case 'cts':
        this.api_url = environment.api_url_cts;
        break;
    }

    return this.http
    .post<FotoProfilo>(
      this.api_url + 'user/GetFotoProfilo',
        { AnagraficaUtenteIdentifier: { ID: id } }
    ).pipe(
      take(1),
      map(resData => {
        if( resData.Result )
        {
          console.log('resData foto profilo', resData);
          if( resData.FotoProfilo ){
            fotoProfilo = resData.FotoProfilo;
          }
          else{
            fotoProfilo = '';
          }

          localStorage.setItem('immagine', fotoProfilo);
        }
        else
        {
          //Errore
          fotoProfilo = '';
          this.showAlert('Errore nella lettura della foto del profilo');
        }
        //console.log('fotoprofilo', fotoProfilo);

        return fotoProfilo;
      })

    );
    // return fotoProfilo;
  }

  formatDate( data: string )
  {
    if( data !== '' )
    {
      let giorno: String;
      let mese: String;
      let anno: String;
      anno = data.substring(0,4);
      mese = data.substring(6,4);
      giorno = data.substring(8,6);

      return giorno + '/' + mese + '/' + anno;
    }
    else
    {
      return '';
    }
  }

  /* getDati(id: number){
    return this.http
    .get<AnagraficaUtenteSvs>(
      `https://demo.trasportosangueorgani.it/ws/anagrafe.php?id=${id}`
    ).
    pipe(
      take(1),
      map( resData => {
        //console.log('resData',resData);

        if( resData )
        {
          localStorage.setItem('sezione', resData.sezione); // lo salviamo perchè da questo è necessario ricavare la provincia di sede nella nuova richiesta
          const dati = [];
          dati.push(new MyUserData(
            resData.cognome,
            resData.nome,
            id,
            resData.codice_fiscale,
            this.formatDate(resData.data_nascita),
            resData.luogo_nascita,
            resData.indirizzo,
            resData.numero_civico,
            resData.comune,
            resData.provincia,
            resData.cap,
            this.formatDate(resData.data_inizio_attivita),
            resData.sezione,
            resData.recapiti,
            resData.immagine
          ));
          return dati[0];
        }
        else
        {
          //Errore
          this.showAlert(resData.errore);
        }
      }),
      tap(data => {
        //console.log('data', data);
        this._myData.next(data);
      })
    );
  } */

 private showAlert(message: string) {
    this.alertCtrl
      .create({
        header: 'Lettura dati fallita',
        message,
        buttons: [
         'Okay'
        ],
      })
      .then(alertEl => alertEl.present());
  }


}
