/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */

//Interfacce per i nuovi metodi di Gruppo Informatico
export enum eStatoRichiesta {
  Non_Specificato = 0,
  Richiesta = 1,
  Missione_Rifiutata = 2,
  Missione_Annullata = 3,
  Missione_Terminata = 4,
  Missione_In_Corso = 6,
  Missione_Presa_In_Consegna = 7,
  Missione_In_Corso_Manuale = 8,
  Articolo_Inserito_Nel_Contenitore = 11,
  Contenitore_Inserito_In_Cella = 12,
  Contenitore_Estratto_Da_Cella = 13,
  Articolo_Estratto_Da_Contenitore_E_Consegna = 14,
  Passaggio_Da_Struttura_Per_Prelievo = 15,
  Passaggio_Da_Struttura_Per_Consegna = 16,
  Sigillatura = 17,
  Check_Integrita_sigillo = 18,
  Operazione_posticipata = 50,
  Operazione_annullata = 51,
  Ingresso_reparto_partenza = 100,
  Prelievo_prodotto = 101,
  Uscita_reparto_partenza = 102,
  Ingresso_reparto_destinazione = 103,
  Consegna_prodotto = 104,
  Uscita_reparto_destinazione = 105
}

export enum TipoContenitore {
  Non_Specificato = 0,
  Primario = 1,
  Secondario = 2,
  Terziario = 3,
  Altro = 9

}

export enum eTipoOperazioneItinerario {
  Non_Specificato,
  Prelievo,
  Consegna
}

export enum TipoServizio {
  Non_Specificato = 0,
  Esterno_Materiali_Ordinario = 901,
  Esterno_Materiali_Emergenza = 902,
  Interno_Materiali_Ordinario = 903,
  Interno_Materiali_Emergenza = 904,
  Interno_Pazienti_Ordinario = 905,
  Interno_Pazienti_Emergenza = 906

}

export class RichiestaIdentifierModel {
  constructor(
    public Esercizio: string,
    public Registro: string,
    public Numero: number
  ) { }
}
export class RichiestaBaseModel {
  constructor(
    public Identifier: RichiestaIdentifierModel,
    public TipoServizio: TipoServizio,
    public Causale: CausaleTrasportiBaseModel,
    public Descrizione: string
  ) { }
}

export class RichiestaModel extends RichiestaBaseModel {
  constructor(
    public Identifier: RichiestaIdentifierModel,
    public TipoServizio: TipoServizio,
    public Causale: CausaleTrasportiBaseModel,
    public Descrizione: string,
    public Missioni: PrenotazioneModel[]
  ) {
    super(Identifier, TipoServizio, Causale, Descrizione);
  }
}

export class CausaleTrasportiIdentifierModel {
  constructor(
    public Codice: string
  ) { }
}

export class CausaleTrasportiBaseModel {
  constructor(
    public Identifier: CausaleTrasportiIdentifierModel,
    public Descrizione: string
  ) { }
}

export class CoordinateModel {
  constructor(
    public Latitudine: number, //float nel servizio
    public Longitudine: number //float nel servizio
  ) { }
}

export class PdiIdentifierModel {
  constructor(
    public Codice: number
  ) { }
}

export class PdiBaseModel {
  constructor(
    public Identifier: PdiIdentifierModel,
    public Descrizione1: string,
    public Descrizione2: string,
    public Descrizione3: string,
    public Coordinate: CoordinateModel
  ) { }
}

export class PdiModel extends PdiBaseModel {
  constructor(
    public Identifier: PdiIdentifierModel,
    public Descrizione1: string,
    public Descrizione2: string,
    public Descrizione3: string,
    public Coordinate: CoordinateModel
  ) {
    super(Identifier, Descrizione1, Descrizione2, Descrizione3, Coordinate);
  }
}

export class DimensioneModel {
  constructor(
    public Larghezza: number,
    public Altezza: number,
    public Profondita: number
  ) { }
}

export class ItinerarioSpecificheArticoloModel {
  constructor(
    public Dimensione: DimensioneModel,
    public TemperaturaMinima: number,
    public TemperaturaMassima: number,
    public TipoContenitore: TipoContenitore,
    public Peso: number
  ) { }
}

export class ArticoloIdentifierModel {
  constructor(
    public Codice: string
  ) { }
}

export class ArticoloBaseModel {
  constructor(
    public Identifier: ArticoloIdentifierModel,
    public Flann: string,
    public Attivo: boolean,
    public Barcode: string,
    public Descrizione: string,
    public DescrizioneAggiuntiva: string
  ) { }
}

export class ArticoloModel extends ArticoloBaseModel {
  constructor(
    public Identifier: ArticoloIdentifierModel,
    public Flann: string,
    public Attivo: boolean,
    public Barcode: string,
    public Descrizione: string,
    public DescrizioneAggiuntiva: string,
    public TipoContenitore: TipoContenitore,
    public Label_TipoContenitore: string,
    public Dimensione: DimensioneModel,
    public Peso: number,
    public TemperaturaMinima: number,
    public TemperaturaMassima: number
  ) {
    super(Identifier, Flann, Attivo, Barcode, Descrizione, DescrizioneAggiuntiva);
  }
}

export class RichiestaProdottiIdentifier {
  constructor(
    public Esercizio: string,
    public Numero: number,
    public NumeroRigaItinerario: number,
    public NumeroRigaMissione: number,
    public Registro: string,
    public NumeroRigaStato: number
  ) { }
}

export class RichiestaProdottiBaseModel {
  constructor(
    public Identifier: RichiestaProdottiIdentifier,
    public StatoRichiesta: eStatoRichiesta,
    public DataComunicazione: string,
    public OraComunicazione: string,
    public Coordinate: CoordinateModel
  ) { }
}

export class RichiestaProdottiModel extends RichiestaProdottiBaseModel {
  constructor(
    public Identifier: RichiestaProdottiIdentifier,
    public StatoRichiesta: eStatoRichiesta,
    public DataComunicazione: string,
    public OraComunicazione: string,
    public Coordinate: CoordinateModel
  ) {
    super(Identifier, StatoRichiesta, DataComunicazione, OraComunicazione, Coordinate);
  }
}

export class ItinerarioIdentifierModel extends RichiestaIdentifierModel {
  constructor(
    public Esercizio: string,
    public Registro: string,
    public Numero: number,
    public NumeroRigaItinerario: number
  ) {
    super(Esercizio, Registro, Numero);
  }
}

export class ItinerarioBaseModel {
  constructor(
    public Identifier: ItinerarioIdentifierModel,
    public Annotazioni: string,
    public OrarioPartenza: string,
    public OrarioArrivo: string,
    public Quantita: number,
    public CausaleIdentifier: CausaleTrasportiIdentifierModel,
    public TipoOperazione: eTipoOperazioneItinerario,
    public Articolo: ArticoloModel,
    public PdiPartenza: PdiBaseModel,
    public PdiArrivo: PdiBaseModel,
    public SpecificheArticolo: ItinerarioSpecificheArticoloModel
  ) { }
}

export class ItinerarioModel extends ItinerarioBaseModel {
  constructor(
    public Identifier: ItinerarioIdentifierModel,
    public Annotazioni: string,
    public OrarioPartenza: string,
    public OrarioArrivo: string,
    public Quantita: number,
    public CausaleIdentifier: CausaleTrasportiIdentifierModel,
    public TipoOperazione: eTipoOperazioneItinerario,
    public Articolo: ArticoloModel,
    public PdiPartenza: PdiBaseModel,
    public PdiArrivo: PdiBaseModel,
    public RichiesteProdotti: RichiestaProdottiModel[],
    public SpecificheArticolo: ItinerarioSpecificheArticoloModel,
    public StatoAttuale: eStatoRichiesta
  ) {
    // super(Identifier,Annotazioni,OrarioPartenza,OrarioArrivo,Quantita,CausaleIdentifier,TipoOperazione,Articolo,PdiPartenza,PdiArrivo);
    super(Identifier, Annotazioni, OrarioPartenza, OrarioArrivo, Quantita, CausaleIdentifier, TipoOperazione, Articolo, PdiPartenza, PdiArrivo, SpecificheArticolo);
  }

}
export class AutomezzoIdentifierModel {
  constructor(
    public Targa: string
  ) { }
}

export class AutomezzoBaseModel {
  constructor(
    public Identifier: AutomezzoIdentifierModel,
    public Sigla: string
  ) { }
}

export class PrenotazioneIdentifierModel {
  constructor(
    public Esercizio: string,
    public Numero: number,
    public NumeroRigaMissione: number,
    public Registro: string
  ) { }
}

export class PrenotazioneBaseModel {
  constructor(
    public Identifier: PrenotazioneIdentifierModel,
    public Assegnazione: string,
    public DataViaggio: string,
    public OraViaggio: string,
    public StatoRichiesta: eStatoRichiesta,
    public Color_background: string,
    public Color_siren: string,
    public Color_border: string,
    public ArrowDetail: string,
    public Automezzo: AutomezzoBaseModel,
    public Itinerari: ItinerarioModel[]
  ) { }
}

export class PrenotazioneModel extends PrenotazioneBaseModel {
  constructor(
    public Identifier: PrenotazioneIdentifierModel,
    public Assegnazione: string,
    public DataViaggio: string,
    public OraViaggio: string,
    public StatoRichiesta: eStatoRichiesta,
    public Color_background: string,
    public Color_siren: string,
    public Color_border: string,
    public ArrowDetail: string,
    public Automezzo: AutomezzoBaseModel,
    public Itinerari: ItinerarioModel[]
  ) {
    super(Identifier, Assegnazione, DataViaggio, OraViaggio, StatoRichiesta, Color_background, Color_siren, Color_border, ArrowDetail, Automezzo, Itinerari);
  }
}

export class MissioneModel {
  constructor(
    public MissioneEsterna: string,
    public Prenotazione: PrenotazioneModel,
    public Richiesta: RichiestaBaseModel
  ) { }
}
