/* eslint-disable @typescript-eslint/naming-convention */
export const environment = {
  production: true,
  firebaseAndroidAPIKey: 'AIzaSyAkPycysPnidlgaBZqEU-2FVtTYozQtcWE',
  firebaseWebAPIKey: 'AIzaSyANR15IINthhw3_EsGjyn_5n-DpjMjwtvo',
  api_url_svs: 'https://api2.trasportosangueorgani.it/',
  api_url_cts: 'https://api.consorziotoscanaservizi.it/'
  /* api_url_svs: 'https://api.trasportosangueorgani.it/',
  api_url_cts: 'https://apitest.trasportosangueorgani.it/' */
};
