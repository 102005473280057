/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import { environment } from './../../environments/environment';
import { AuthenticateModel } from './auth.model';
import { LoadingController, AlertController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { User } from './user.model';
import { PushNotifications, Token } from '@capacitor/push-notifications';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  isLoading = false;
  private _user = new BehaviorSubject<User>(null);
  token_fcm: string;

  get userIsAuthenticated() {
    return  this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return !!user.JWTToken;
        } else {
          return false;
        }
      })
    );
  }

  get userId() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.userid;
        } else {
          return null;
        }
      })
    );
  }

  get username() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.utente;
        } else {
          return null;
        }
      })
    );
  }

  get nome_cognome() {
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.nome + ' ' + user.cognome;
        } else {
          return null;
        }
      })
    );
  }

  get JWTToken() {
    // eslint-disable-next-line no-underscore-dangle
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.JWTToken;
        } else {
          return null;
        }
      })
    );
  }

  get refreshToken() {
    // eslint-disable-next-line no-underscore-dangle
    return this._user.asObservable().pipe(
      map(user => {
        if (user) {
          return user.RefreshToken;
        } else {
          return null;
        }
      })
    );
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController
  ) {}

    password = '';
    utente = '';
    azienda: string = localStorage.getItem('azienda');
    private mySubjectLogout = new Subject<any>();
    obLogout = this.mySubjectLogout.asObservable();
    private api_url = '';

    serviceLogout(value: any) {
      this.mySubjectLogout.next(value);
    }

    private mySubjectMaterialeSanificazione = new Subject<any>();
    private mySubjectMateriale = new Subject<any>();
    private mySubjectProspetto = new Subject<any>();
    private mySubjectNetwork = new Subject<any>();
    obMaterialeSanificazione = this.mySubjectMaterialeSanificazione.asObservable();
    obMateriale = this.mySubjectMateriale.asObservable();
    obProspetto = this.mySubjectProspetto.asObservable();
    obNetwork = this.mySubjectNetwork.asObservable();

    serviceMaterialeSanificazione(value: any) {
      this.mySubjectMaterialeSanificazione.next(value);
    }

    serviceMateriale(value: any) {
     /*  value = false;
      if( this.azienda === 'svs'){
        value = true;
      } */
      this.mySubjectMateriale.next(value);
    }

    serviceProspetto(value: any){
      this.mySubjectProspetto.next(value);
    }

    serviceNetwork(value: any){
      this.mySubjectNetwork.next(value);
    }

    autoLogin() {
      // alert('network autologin' + localStorage.getItem('networkok'));
      if( localStorage.getItem('networkok') && localStorage.getItem('networkok') === '1' ){

        this.serviceNetwork(true);

        if (localStorage.getItem('password') !== '' && localStorage.getItem('password') !== null) {
          this.password = localStorage.getItem('password');
        }
        if (localStorage.getItem('utente') !== '' && localStorage.getItem('utente') !== null) {
          this.utente = localStorage.getItem('utente');
        }

        console.log('autologin utente', this.utente);
        console.log('autologin password', this.password);

        this.azienda = localStorage.getItem('azienda');
        try {
          return from(this.utente).pipe(
            map(userData => {
              if (!userData || userData === '') {
                return null;
              }

              this.isLoading = true;
              //Leggiamo le credenziali e verifichiamo se sono esistenti
              if (this.utente !== '' && this.utente !== null && this.password !== '' && this.password !== null) {
                this.loadingCtrl
                  .create({ keyboardClose: true, message: 'Login in corso...' })
                  .then(loadingEl => {
                    loadingEl.present();
                    let authObs: Observable<AuthenticateModel>;
                    authObs = this.login(this.utente, this.password, this.azienda);
                    authObs.subscribe(
                      resData => {
                        //Salvataggio in locale storage dei dati del Token
                        localStorage.setItem('JWTToken', resData.Token.JWTToken);
                        localStorage.setItem('RefreshToken', resData.Token.RefreshToken);
                        localStorage.setItem('ID', resData.AnagraficaUtente.Identifier.ID.toString());

                        this.serviceLogout(true);

                        // On success, we should be able to receive notifications
                        PushNotifications.addListener('registration',
                        (token: Token) => {
                          console.log('Push registration success, token: ', token.value);
                          this.token_fcm = token.value;
                          // this.presentToast("Push registration success" + token.value);
                          this.saveFcmToken(token.value);
                        }
                      );

                      // Some issue with our setup and push will not work
                      PushNotifications.addListener('registrationError',
                        (error: any) => {
                          console.log('Error on registration: ', JSON.stringify(error));
                        }
                      );

                        if( !localStorage.getItem('loadpush') || localStorage.getItem('loadpush') === '0'){
                          this.router.navigateByUrl('/dati');
                        }
                        else{
                          this.router.navigateByUrl('/nuova-missione');
                        }

                        if( this.utente.toLowerCase() === 'fcarnovale' || this.utente.toLowerCase() === 'mcolini'
                            || this.utente.toLowerCase() === 'fmalara' || this.utente.toLowerCase() === 'emancina' )
                        {
                          if( this.azienda === 'svs' ){
                            this.serviceMaterialeSanificazione(true);
                          }
                          else{
                            this.serviceMateriale(false);
                          }
                        }
                        else{
                          this.serviceMaterialeSanificazione(false);
                        }

                        if( localStorage.getItem('azienda') === 'svs' ){
                          this.serviceMateriale(true);
                          this.serviceProspetto(true);
                        }
                        else{
                          this.serviceMateriale(false);
                          this.serviceProspetto(false);
                        }

                        if (resData) {
                          this.isLoading = false;
                          loadingEl.dismiss();
                          this.router.navigateByUrl('/dati');
                        }
                      },
                      errRes => {
                        loadingEl.dismiss();
                        this.showAlert('Utente e/o password errati');
                      }
                    );
                  });
              }else {
                this.showAlert('Utente e/o password errati.');
              }
            })
          );
        } catch {
          if (localStorage.getItem('targa') !== '' && localStorage.getItem('targa') !== null) {
            localStorage.removeItem('targa');
          }
          if (localStorage.getItem('ID') !== '' && localStorage.getItem('ID') !== null) {
            localStorage.removeItem('ID');
          }

          this.serviceLogout(false);
          this.serviceMaterialeSanificazione(false);
          this.serviceProspetto(false);

          this.router.navigateByUrl('/auth');
        }
      }
      else{
        this.serviceNetwork(true);
        this.router.navigateByUrl('/auth');
      }

  }

  login(utente: string, password: string, azienda: string) {

    console.log('azienda login', azienda);
    localStorage.setItem('azienda', azienda);
    switch(azienda)
    {
      case 'svs':
        this.api_url = environment.api_url_svs;
        break;
      case 'cts':
        this.api_url = environment.api_url_cts  ;
        break;
    }

    console.log('api url', this.api_url);
    const response = this.http
    .post<AuthenticateModel>(
      this.api_url + 'login/authenticate',
        { email: utente, password }
    )
    .pipe(
      tap(this.setUserData.bind(this)));
    console.log('response authenticate',JSON.stringify(response));
    return response;
  }

  logout() {

    if (localStorage.getItem('targa') !== '' && localStorage.getItem('targa') !== null) {
      localStorage.removeItem('targa');
    }
    if (localStorage.getItem('targhe') !== '' && localStorage.getItem('targhe') !== null) {
      localStorage.removeItem('targhe');
    }
    if (localStorage.getItem('ID') !== '' && localStorage.getItem('ID') !== null) {
      localStorage.removeItem('ID');
    }
    if (localStorage.getItem('immagine') !== '' && localStorage.getItem('immagine') !== null) {
      localStorage.removeItem('immagine');
    }
    if (localStorage.getItem('getdatioffline') !== '' && localStorage.getItem('getdatioffline') !== null) {
      localStorage.removeItem('getdatioffline');
    }
    if( !localStorage.getItem('memologin') && localStorage.getItem('memologin') === '0' ){
      localStorage.removeItem('utente');
      localStorage.removeItem('password');
      localStorage.removeItem('JWTToken');
      localStorage.removeItem('RefreshToken');
    }

    this._user.next(null);

    this.serviceLogout(false);
    this.serviceMaterialeSanificazione(false);
    this.serviceMateriale(false);
    this.serviceProspetto(false);

    this.router.navigateByUrl('/auth');

  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy() {
  }

  private setUserData(userData: AuthenticateModel) {
    const user = new User(
      userData.AnagraficaUtente.Identifier.ID,
      userData.AnagraficaUtente.Credenziali.Email,
      userData.AnagraficaUtente.Nome,
      userData.AnagraficaUtente.Cognome,
      userData.AnagraficaUtente.Sesso.sesso,
      userData.AnagraficaUtente.Stato.Stato,
      userData.Token.JWTToken,
      userData.Token.RefreshToken
    );
    this._user.next(user);
  }

  private showAlert(message: string) {
    this.alertCtrl
      .create({
        header: 'Autenticazione Fallita',
        message,
        buttons: [
          { text: 'Okay',
            handler: () => {
              this.router.navigateByUrl('/auth');
            }
          }
        ],
      })
      .then(alertEl => alertEl.present());
  }

  saveFcmToken(push_token: string) {
    console.log('token_fcm', push_token);

    if (push_token !== '' && localStorage.getItem('networkok') && localStorage.getItem('networkok') === '1') {
      const id = localStorage.getItem('ID');
      const azienda = localStorage.getItem('azienda');

      //salvataggio del token nella tabella ANAGRAFE_FCMTOKEN
      console.log('chiamata fcm token', `https://demo.trasportosangueorgani.it/ws/fcm.php?id=${id}&token=${push_token}&azienda=${azienda}`);
      return this.http
        .get<any>(
          `https://demo.trasportosangueorgani.it/ws/fcm.php?id=${id}&token=${push_token}&azienda=${azienda}`
        ).
        subscribe(resData => console.log('resdata save fcmtoken', JSON.stringify(resData)));
    }
  }

}
